import React from "react";
import PropTypes from "prop-types";
import { Box, Stack } from "grommet";
import Img from "gatsby-image";

const SliderImage = ({ src }) =>{
  return (
      <Stack anchor="bottom">
        <Box
          round="large"
          overflow="hidden"
          align="center"
          margin="small"
          height="large"
        >
          <img src={src.childImageSharp.fluid.src} alt="" /> 
        </Box>
      </Stack>
)};

SliderImage.propTypes = {

  src: PropTypes.object
};

export default SliderImage;
