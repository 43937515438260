import React from "react";
import { Box } from "grommet";
import { Tile } from "./Tile";
import { FeatureEmoji } from "../emojis";
import { data } from "./data";

export const Features = ({ ...rest }) => (
  <Box
    direction="row-responsive"
    justify="center"
    align="center"
    {...rest}
    margin={{ top: "xlarge" }}
  >
    <Tile direction="row" action="See demo site" link="https://demo.hyperengine.app" summary={data[0]}>
      <FeatureEmoji name=":zap:" />
    </Tile>
    <Tile direction="row" summary={data[1]}>
      <FeatureEmoji flip name=":thumbsup:" />
    </Tile>
    <Tile direction="row" summary={data[2]}>
        <FeatureEmoji name=":speech_balloon:" />
    </Tile>
  </Box>
);

export default Features;
