import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Box, Heading, Paragraph } from "grommet";

export const StyledHeading = styled(Heading)`
  font-weight: 200;

`;

const ComingSoon = ({ size, ...rest }) => (
  <Fragment>
    <Box direction="row" {...rest}>
      <StyledHeading size={size}>Coming</StyledHeading>
      <Heading size={size}>Soon</Heading>
    </Box>
    <Paragraph margin={{ top: "none" }} textAlign="center" size="xxlarge">
      get early access and lock in our introductory pricing.
    </Paragraph>
  </Fragment>
);

ComingSoon.propTypes = {
  size: PropTypes.string.isRequired
};

export { ComingSoon };
