import React, {useContext} from "react";
import Slider from "react-slick";
import { useStaticQuery, Link } from "gatsby";
import { Box, ResponsiveContext } from "grommet";
import  SliderImage  from "./SliderImage";

const imageQ = graphql`
query SliderQuery {
 slide1: file(relativePath: { eq: "superlative-co-CvF4KcRJGAg-unsplash.jpg" }) {
   childImageSharp {
     fluid(maxWidth: 558, maxHeight: 836) {
       ...GatsbyImageSharpFluid
     }
   }
 }
 slide2: file(relativePath: { eq: "brayden-law-Q-1DRoeE6Ko-unsplash.jpg" }) {
   childImageSharp {
     fluid(maxWidth: 558, maxHeight: 836) {
       ...GatsbyImageSharpFluid
     }
   }
 }
 slide3: file(relativePath: { eq: "chris-nguyen-aTX_bRaOZnA-unsplash.jpg" }) {
   childImageSharp {
     fluid(maxWidth: 558, maxHeight: 836) {
       ...GatsbyImageSharpFluid
     }
   }
 }
 slide4: file(relativePath: { eq: "cris-ovalle-0xIseRr_WVc-unsplash.jpg" }) {
   childImageSharp {
     fluid(maxWidth: 558, maxHeight: 836) {
       ...GatsbyImageSharpFluid
     }
   }
 }

}
`;

const InstaSlider = () => {
  const data = useStaticQuery(imageQ);

 const size =  useContext(ResponsiveContext);


  const galleryItems = () => {
    // console.log(Object.values(data).filter(item => !!item));


    return Object.values(data).filter(item => !!item).map((item, index) => (
      <SliderImage
        src={item}
        key={item.childImageSharp.fluid.src}
      />
    ));
  }

  const getNumOfItemsToShow = (size) => {
    return size === "xsmall"
      ? 2
      : size === "small"
      ? 3
      : size === "medium"
      ? 4
      : 5;
  }


    const items = galleryItems();
    const settings = {
      className: "center",
      infinite: items.length > getNumOfItemsToShow(size),
      slidesToScroll: 1,
      speed: 1200,
      autoplay: true
    };

    return (
  
          <>

          <Box pad="large">
            <Slider slidesToShow={getNumOfItemsToShow(size)} {...settings}>
              {items}
            </Slider>
          </Box>
          </>
 
    );
  
}

export { InstaSlider as Slider };
