import React, {useState} from "react";
import { Box, Button, Text, TextInput } from "grommet";

const Sign = () => {
  const [state, setState] = useState({ value: "", focus: false });


    const { focus, value } = state;
    return (
      <Box direction="row" width="large" justify="center">
        <form name="signup" method="POST" data-netlify="true">
        <Box
          direction="row"
          align="center"
          width="medium"
          // setopacity
          border={{ side: "bottom", color: focus ? "focus" : "brand" }}
        >
          <TextInput
            plain
            placeholder={<Text size="small">your email address</Text>}
            value={value}
            onChange={event => setState({ ...state, value: event.target.value })}
            onFocus={() => setState({ ...state, focus: true })}
            onBlur={() => setState({ ...state, focus: false })}
          />
        </Box>
        <Button onClick={() => {}}>
          <Box
            round="xlarge"
            background="accent-1"
            pad={{ vertical: "small", horizontal: "medium" }}
          >
            <Text size="small" color="brand" weight="bold" textAlign="center">
              Join us
            </Text>
          </Box>
        </Button>
        </form>
      </Box>
    );
  
}

export { Sign };
