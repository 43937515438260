/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import {  Grommet } from "grommet";

import { customTheme } from "./../theme";


import NavHeader from "./NavHeader";
import Footer from "./Footer";
import Section  from "./Section";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Grommet theme={customTheme} full>
        <NavHeader />

        <main>{children}</main>
        <Section>
          <Footer />
        </Section>
      </Grommet>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
